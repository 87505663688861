<template>
  <div class="consumer-info-panel box-shadow">
    <a-spin :spinning="loading">
      <a-row>
        <a-col
          :span="8"
          :xs="{ span: 24 }"
          :sm="{ span: 12 }"
          :md="{ span: 8 }"
        >
          <div class="consumer-info-panel__desc">Property</div>
          <h2>
            {{ propertyName }}
            <!--            <a-tooltip-->
            <!--              placement="right"-->
            <!--              title="The system is running in good health"-->
            <!--              :overlayStyle="{ minWidth: 300 }"-->
            <!--            >-->
            <!--              <a-progress-->
            <!--                :style="{ marginLeft: 20 }"-->
            <!--                :percent="100"-->
            <!--                :steps="5"-->
            <!--                size="small"-->
            <!--                strokeColor="#1890ff"-->
            <!--                class="consumer-info-panel__progress"-->
            <!--              />-->
            <!--            </a-tooltip>-->
          </h2>
        </a-col>
        <a-col
          :span="8"
          :xs="{ span: 24 }"
          :sm="{ span: 12 }"
          :md="{ span: 8 }"
        >
          <div class="consumer-info-panel__desc">
            Please select a period to view details
          </div>
          <a-range-picker
            :style="{ maxWidth: 500 }"
            :allowClear="false"
            :defaultValue="rangeDates"
            format="DD/MM/YYYY"
            :disabledDate="disabledDate"
            @change="datePickerOnChange"
          />
          <a-button
            type="primary"
            class="consumer-info-panel__apply"
            @click="apply"
          >
            Apply
          </a-button>
        </a-col>
      </a-row>
    </a-spin>
  </div>
</template>

<script>
export default {
  name: 'InfoPanel',
  props: {
    /** loading state */
    loading: Boolean,

    /** property name */
    propertyName: String,

    /** callback when the picker changed */
    datePickerOnChange: Function,

    /** picker data source */
    rangeDates: Array,

    /** for controlling the disabled date */
    billFrom: Object,

    /** Apply the input parameters and fetch the data */
    apply: Function,
  },
  methods: {
    disabledDate(current) {
      return !(
        current < this.$moment() &&
        this.billFrom &&
        current >= this.billFrom.startOf('day')
      );
    },
  },
};
</script>

<style lang="less" scoped>
@import '../assets/style/variables';

.consumer-info-panel {
  height: 100%;
  background-color: #fff;
  padding: 25px 30px;

  &__desc {
    margin-bottom: 5px;
  }

  &__progress {
    .ant-progress-steps-item {
      background-color: @main-color !important;
    }
  }

  &__apply {
    margin-left: 11px;
  }
}

@media screen and (max-width: @mobile-width) {
  .consumer-info-panel {
    &__apply {
      margin-top: 10px;
      width: 100%;
      margin-left: 0px;
    }
  }
}
</style>
