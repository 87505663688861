<template>
  <Card title="Solar Delivery Method" no-footer>
    <a-select
      style="width: 100%"
      v-model:value="form.solarDeliveryMethod"
      :loading="loading"
      @change="methodChanged"
      :disabled="isReadonly"
      data-tn="solshare-info-solar-delivery-method"
    >
      <a-select-option
        v-if="!is2P"
        :value="SOLAR_DELIVERY_METHOD.FULLY_OPTIMISED"
        data-tn="solshare-info-solar-delivery-method-optimised"
      >
        Fully optimised
      </a-select-option>

      <a-select-option
        v-if="!is2P"
        :value="SOLAR_DELIVERY_METHOD.OPTIMISED_AND_EVEN"
        data-tn="solshare-info-solar-delivery-method-optimised-and-even"
      >
        Optimised and even
      </a-select-option>

      <a-select-option
        v-if="is2P"
        :value="SOLAR_DELIVERY_METHOD.NET_METERING"
        data-tn="solshare-info-solar-delivery-method-net-metering"
      >
        Equal allocation
      </a-select-option>

      <a-select-option
        v-if="isUnequalAlgorithm"
        :value="SOLAR_DELIVERY_METHOD.UNEQUAL_ALLOCATION"
        data-tn="solshare-info-solar-delivery-method-unequal"
      >
        Unequal allocation
      </a-select-option>
    </a-select>
  </Card>
  <Card v-if="!loading" title="Solar Distribution">
    <template #extra>
      <a-radio-group
        v-if="
          form.solarDeliveryMethod === SOLAR_DELIVERY_METHOD.UNEQUAL_ALLOCATION
        "
        v-model:value="suffix"
        :disabled="isReadonly"
      >
        <a-radio-button
          :value="UNEQUAL_ALLOCATION_TYPE.PERCENT"
          data-tn="solshare-info-unequal-percentage"
        >
          %
        </a-radio-button>
        <a-radio-button
          :value="UNEQUAL_ALLOCATION_TYPE.KW"
          data-tn="solshare-info-unequal-kw"
        >
          kW
        </a-radio-button>
      </a-radio-group>
    </template>
    <ConnectionGroup
      v-for="phaseIndex in is2P ? [0, 1] : [0, 1, 2]"
      :key="phaseIndex"
      :phase-index="phaseIndex"
      :loading="loading"
      v-model:algorithm-weight="this.form.algorithmWeight[phaseIndex]"
      v-model:enabled-units="this.form.enabledUnits[phaseIndex]"
      :connected-units="connectedUnits.filter((u) => u.phase === phaseIndex)"
      :solarDeliveryMethod="this.form.solarDeliveryMethod"
      :available="available"
      :suffix="suffix"
      :ref="`groups`"
      :readonly="isReadonly"
    />
    <template #footer>
      <a-button
        type="primary"
        @click="saveClicked"
        :loading="saveLoading"
        :disabled="isReadonly"
        data-tn="save-site-config"
      >
        {{
          isReadonly
            ? "We currently don't support changing this in this region"
            : 'Save'
        }}
      </a-button>
    </template>
  </Card>
</template>

<script>
import Card from '@/components/Card';
import ConnectionGroup from './ConnectionGroup';
import {
  COUNTRY_CODE,
  DEFAULT_ALGO_WEIGHT,
  SOLAR_DELIVERY_METHOD,
  UNEQUAL_ALLOCATION_TYPE,
} from '@/utils/constants';
import semver from 'semver';
import { backendToFrontend } from '../../../../utils/array';

export default {
  name: 'SolshareInfo',
  components: {
    Card,
    ConnectionGroup,
  },
  data() {
    return {
      SOLAR_DELIVERY_METHOD,
      UNEQUAL_ALLOCATION_TYPE,
      suffix: UNEQUAL_ALLOCATION_TYPE.PERCENT,
      form: {
        solarDeliveryMethod: '',
        connectedUnits: [[], [], []],
        enabledUnits: [[], [], []],
        algorithmWeight: [],
      },
      validate: false,
      saveLoading: false,
    };
  },
  props: {
    data: Object,
    serialNum: String,
    loading: Boolean,
  },
  watch: {
    data(v) {
      this.form.solarDeliveryMethod = v?.config?.algorithm_type || null;
      this.form.connectedUnits = v?.config?.connected_units || [[], [], []];
      this.form.enabledUnits = v?.config?.enabled_units || [[], [], []];
      this.form.algorithmWeight = backendToFrontend(
        v?.config?.algo_weight || [],
        this.is2P,
      );
    },
  },
  methods: {
    methodChanged(val) {
      switch (val) {
        case SOLAR_DELIVERY_METHOD.UNEQUAL_ALLOCATION:
          this.form.algorithmWeight = this.is2P
            ? DEFAULT_ALGO_WEIGHT.slice(0, 2)
            : DEFAULT_ALGO_WEIGHT.slice();
          break;
        default:
          this.form.algorithmWeight = [];
      }
    },
    async saveClicked() {
      this.saveLoading = true;
      try {
        await Promise.all(this.$refs.groups.map((g) => g.validate()));
        await this.$http.post(this.$api.uploadConfig(this.serialNum), {
          solarDeliveryMethod: this.form.solarDeliveryMethod,
          enabledUnits: this.form.enabledUnits,
          ...(this.form.solarDeliveryMethod ===
            SOLAR_DELIVERY_METHOD.UNEQUAL_ALLOCATION && {
            algorithmWeight: this.form.algorithmWeight,
          }),
        });
        this.$message.success('Configuration saved successfully');
      } catch (e) {
        this.$message.warn('please check your input');
      }
      this.saveLoading = false;
    },
  },
  computed: {
    is2P() {
      return this.serialNum.startsWith('2P');
    },
    isUnequalAlgorithm() {
      if (
        !/3P_35A_\d{4}(_test)?/.test(this.serialNum) &&
        !/2P_100_\d{4}(_test)?/.test(this.serialNum)
      ) {
        return false;
      }
      const number = Number(this.serialNum.match(/\d{4}/)[0]);
      const swVer = semver.valid(this.data?.meter?.softwareVersion) || '0.0.0';
      return (
        semver.gte(swVer, '0.4.5') ||
        (this.serialNum.startsWith('3P') && number >= 167) ||
        (this.serialNum.startsWith('2P') && number >= 20)
      );
    },
    isReadonly() {
      return (
        this.data?.meter?.project?.country === COUNTRY_CODE['United Kingdom'] ||
        this.data?.meter?.project?.country === COUNTRY_CODE['Australia']
      );
    },

    available() {
      return Number(this.data?.meter?.systemSize) / (this.is2P ? 2 : 3);
    },

    connectedUnits() {
      if (!this.form.connectedUnits) {
        return [];
      }
      return this.form.connectedUnits
        .map((phase, i) => {
          return phase.map((conn) => {
            const phase = `L${i + 1}-${conn + 1}`;
            const unit = this.data.unit[conn][`L${i + 1}`];

            return {
              unit: unit || phase,
              enabled: Array.isArray(this.form.enabledUnits)
                ? this.form.enabledUnits[i].includes(conn)
                : true,
              key: `${i}${conn}`,
              phase: i,
              conn,
            };
          });
        })
        .flat();
    },
  },
};
</script>

<style lang="less" scoped></style>
