<template>
  <SolshareSelector
    :meter-value="serialNum"
    :meter-select-on-change="onMeterChange"
    title="Site Configuration"
    description="Select a Solshare to start configuration."
  />
  <SolshareInfo
    v-if="serialNum"
    :data="systemData"
    :serialNum="serialNum"
    :loading="loading"
  />
</template>

<script>
import SolshareSelector from '@/components/SolshareSelector';
import SolshareInfo from '@/components/Tabs/SiteConfigurationTab/components/SolshareInfo';
import { SEARCH_METER_CODE } from '@/utils/constants';
import { SOLAR_DELIVERY_METHOD } from '../../../utils/constants';
export default {
  name: 'SiteConfigurationTab',
  components: { SolshareSelector, SolshareInfo },
  data() {
    return {
      serialNum: '',
      systemData: null,
      loading: false,
    };
  },
  methods: {
    async onMeterChange(serialNum) {
      this.serialNum = serialNum;
      this.loading = true;
      try {
        const {
          data: { code, payload },
        } = await this.$http.get(this.$api.getSolshareInfo(serialNum));
        if (code === SEARCH_METER_CODE.READY_TO_GO) {
          this.systemData = {
            ...payload,
            config: {
              ...payload.config,
              algorithm_type: serialNum.startsWith('2P')
                ? Array.isArray(payload.config.algo_weight) &&
                  payload.config.algo_weight.length > 0
                  ? SOLAR_DELIVERY_METHOD.UNEQUAL_ALLOCATION
                  : SOLAR_DELIVERY_METHOD.NET_METERING
                : payload.config.algorithm_type,
            },
          };
        } else {
          this.systemData = null;
        }
      } catch (e) {
        this.$message.error(`Get Solshare configuration failed: ${e}`);
      }
      this.loading = false;
    },
  },
};
</script>

<style scoped></style>
